// Copyright 2024, Imprivata, Inc.  All rights reserved.

import {
  AuthnModule,
  EventType,
  type FactorOption,
} from '@imprivata-cloud/authn';
import {
  addSpanAttributes,
  tracedFn,
  updateSpanName,
  endOpenSpans,
  getWorkflowId,
  tracingForceFlush,
  createSpan,
} from '@imprivata-cloud/common';
import { WEB_APP_NAME } from '../../shared/constants';
import { QueryParam } from '../../constants';
import { handleAuthnArtifact, setDefaultHeader } from '../../api/api';
import { useEffect, useState } from 'react';
import { ImprHeaders } from '@imprivata-cloud/common';
import { jsonToBase64 } from '@imprivata-cloud/dataprivacy';
import {
  endJourneyAndCloseWindow,
  hideWindow,
  showWindow,
} from '../../api/agent';

export const IcpFlow: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const tenantId = queryParams.get(QueryParam.TenantId) || '';
  const rpRequestId = queryParams.get(QueryParam.RpRequestId) || '';
  const contextResource = {
    resourceType: queryParams.get(QueryParam.ResourceType) || '',
    contextType: queryParams.get(QueryParam.ContextType) || '',
  };

  const username = queryParams.get(QueryParam.LoginHint) || '';
  const codeChallenge = queryParams.get(QueryParam.CodeChallenge) || '';
  const codeChallengeMethod =
    queryParams.get(QueryParam.CodeChallengeMethod) || '';
  const oidcRequestData = {
    codeChallenge,
    codeChallengeMethod,
  };

  const [finished, setFinished] = useState(false);

  useEffect(() => {
    setDefaultHeader(
      ImprHeaders.ImprClientName,
      jsonToBase64({
        name: WEB_APP_NAME,
      }),
    );
  }, []);

  useEffect(() => {
    setDefaultHeader(ImprHeaders.ImprTenantId, tenantId);
  }, [tenantId]);

  useEffect(() => {
    showWindow();
  }, []);

  // scroll the page up when virtual keyboard is hidden (input looses focus)
  useEffect(() => {
    // this fixes an issue on mobile when, after virtual keyboard is hidden, the page
    // remains scrolled down a bit and there is no way to scroll it up because overall
    // container has "overflow: hidden" and there is no scroll bar
    const scrollHandler = () => {
      window.scrollTo(0, 0);
    };
    // 'focusout' - is the same as 'blur' but with the difference that it bubbles up,
    // so that it, unlike 'blur', can be catched on 'window'
    window.addEventListener('focusout', scrollHandler);
    return () => {
      window.removeEventListener('focusout', scrollHandler);
    };
  }, []);

  const onSuccess = tracedFn(
    'IcpFlow--onSuccess',
    async (authorizationCode: string) => {
      const {
        redirectUri,
        authorizationCode: responseAuthorizationCode,
        state,
      } = await handleAuthnArtifact({
        rpRequestId,
        authorizationCode,
      });

      setFinished(true);
      hideWindow();

      const stateParam = state ? `&state=${state}` : '';
      addSpanAttributes({ redirectUri, state });
      endOpenSpans();
      // This span is the last span before the browser is redirected to another page. If that span is visible in tracing collector it means that tracing was shutdown gracefully and all spans made it through to the server
      createSpan('redirect', { redirectUri }).end();
      await tracingForceFlush();
      window.location.href = `${redirectUri}?code=${responseAuthorizationCode}${stateParam}`;
    },
  );

  if (finished) {
    return <></>;
  }

  return (
    <div className="authn-container">
      <AuthnModule
        contextResource={contextResource}
        tenantId={tenantId}
        onEvent={tracedFn(
          'on-event--',
          ({ event, data }) => {
            console.log('[AUTHN MODULE EVENT]', event, data);
            updateSpanName(`on-event--${event}`);

            if (event === EventType.AUTHENTICATED) {
              onSuccess(data?.oidcAuthorizationCode || '');
            } else if (event === EventType.CANCEL) {
              endJourneyAndCloseWindow();
            }
          },
          { root: true },
        )}
        factors={[{ factorType: 'face' } as FactorOption]}
        requestConfig={{
          clientName: WEB_APP_NAME,
          username,
          oidcRequestData,
        }}
        hideAddAnotherMethod
        tracing={{
          workflowId: getWorkflowId() || '',
        }}
      />
    </div>
  );
};
