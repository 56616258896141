// Copyright 2024, Imprivata, Inc.  All rights reserved.

import type { TraceContext } from '@imprivata-cloud/common';
import { getWorkflowId, getTraceContext } from '@imprivata-cloud/common';

type ImprAgentApi = {
  window: {
    showWindow2: (
      workflowId: string,
      traceContext: TraceContext,
      value: Array<'hide' | 'show' | 'showmaximized'>,
    ) => void;
  };
  authn: {
    endJourneyAndCloseWindow: (
      workflowId: string,
      traceContext: TraceContext,
    ) => void;
  };
};

declare global {
  interface Window {
    impr?: ImprAgentApi;
  }
}

export const showWindow = () => {
  if (window.impr?.window.showWindow2) {
    window.impr?.window.showWindow2(getWorkflowId(), getTraceContext(), [
      'show',
    ]);
  }
};

export const hideWindow = () => {
  if (window.impr?.window.showWindow2) {
    window.impr?.window.showWindow2(getWorkflowId(), getTraceContext(), [
      'hide',
    ]);
  }
};

export const endJourneyAndCloseWindow = () => {
  if (window.impr) {
    window.impr.authn.endJourneyAndCloseWindow(
      getWorkflowId(),
      getTraceContext(),
    );
  }
};
