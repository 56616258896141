// Copyright 2024, Imprivata, Inc.  All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom';
import { initializeTracing } from '@imprivata-cloud/common';
import getConfig from './appConfigUtils';
import './index.less';
import { IcpFlow } from './containers/icp-flow/IcpFlow';

const { ZIPKIN_TRACING_URL, OTLP_TRACING_URL, API_URL } = getConfig();
const workflowId =
  new URLSearchParams(window.location.search).get('workflowId') || '';

initializeTracing({
  workflowId,
  serviceName: 'idp-web-ui',
  // ignoring .onnx files, they are related to paravision sdk and are cluttering the traces
  ignoreUrls: [/\/models\/.+\.onnx$/],
  propagateTraceHeaderCorsUrls: [RegExp(`^${API_URL}.+`)],
  ZIPKIN_TRACING_URL,
  OTLP_TRACING_URL,
});

ReactDOM.render(
  <React.StrictMode>
    <IcpFlow />
  </React.StrictMode>,
  document.getElementById('root'),
);
